import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { IoIosCheckmark } from 'react-icons/io'
import { Container, Row, Col, Card, CardImgOverlay } from 'reactstrap'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const ToursPageEn = ({ data, location }) => (
  <Layout location={location}>
    <Helmet
      title="Geführte Touren im Salzkammergut - Oberösterreich"
      meta={[
        { name: 'description', content: 'Die schönsten geführten Touren durch das Salzkammergut, Hallstatt, Bad Goisern, Bad Ischl, Bad Aussee, St. Wolfgang, Gmunden, Salzburg, Linz und viele weitere.' },
        { name: 'keywords', content: 'Touren, Guide, Salzkammergut, Hallstatt, Bad Aussee, St. Wolfgang, Gmunden, Bad Goisern, Salzburg, Linz, Austria Guides' },
      ]} />
    <Img fluid={data.imageHead.childImageSharp.fluid} />

    <Container className="py-0 py-lg-4">
      <Row className="my-5">
        <Col lg="7">
          <h6 className="text-uppercase">Our strength is diversity</h6>
          <h1 className="mb-3 text-uppercase">Your Austria Guides</h1>
          <p className="text-justify">Austria Guides are trained and <strong>state-certified tour guides</strong>. We want to share our knowledge with you during one of our many tours.</p>
          <p className="text-justify">We are happy to provide consultation in order to find your perfect tour and build a customized excursion programme.</p>
        </Col>
        <Col lg={{ size: 4, offset: 1 }} className="mt-4 mt-lg-0">
          <h6 className="text-uppercase">Our Offers</h6>
          <ul className="list-unstyled mt-4">
            <li><IoIosCheckmark className="mr-2" /> city and town tours</li>
            <li><IoIosCheckmark className="mr-2" /> bus tours</li>
            <li><IoIosCheckmark className="mr-2" /> themed tours</li>
            <li><IoIosCheckmark className="mr-2" /> lantern tours</li>
            <li><IoIosCheckmark className="mr-2" /> seasonal tours</li>
            <li><IoIosCheckmark className="mr-2" /> children and school group tours</li>
            <li><IoIosCheckmark className="mr-2" /> business customer guidance</li>
            <li><IoIosCheckmark className="mr-2" /> social programmes for seminars and conferences</li>
          </ul>
        </Col>
      </Row>

      <h6 className="text-uppercase">Our tour suggestions for you</h6>

      <Row>
        {data.toursEn.edges.map(({ node }, idx) => (
          <Col md="4" key={idx}>
            <Link to={node.fields.slug}>
              <Card className="text-white text-center mt-4 shadow-lg zoom">
                <Img fluid={node.frontmatter.cover.childImageSharp.fluid} className="rounded" />
                <CardImgOverlay className="d-flex">
                  <div className="my-auto mx-auto text-center">
                    <h4 className="card-title">{node.frontmatter.title}</h4>
                  </div>
                </CardImgOverlay>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>

    </Container>
  </Layout>
)

export const query = graphql`
  query {
    imageHead: file(relativePath: { eq: "bad-ischl-kaiservilla.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1250, maxHeight: 420, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    toursEn: allMarkdownRemark(
      filter: { 
        fileAbsolutePath: {regex: "/src\/pages\/tours/"}
        fields: { langKey: {eq: "en"} }
        frontmatter: { category: { eq: "tours" } }
      }
      limit: 1000
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover {
              childImageSharp {
                id
                fluid(maxWidth: 400, maxHeight: 200, quality: 85) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ToursPageEn;